import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { BackofficeStateModel } from './backoffice.state.model';
import { setActions, setDetailsToAprroval, setMenuAction, setModulesAction, setRolAction } from './backoffice.actions';


@State<BackofficeStateModel>({
    name: 'BackofficeState',
    defaults: {
        roles: [],
        navMenu: [],
        modules: [],
        actions: [],
        detailsToAprroval: []
    }
})
@Injectable()
export class BackofficeState {

    @Selector()
    static getRoles(state: BackofficeStateModel) {
        return state.roles;
    }
    @Selector()
    static getModules(state: BackofficeStateModel) {
        return state.modules;
    }

    @Selector()
    static getMenu(state: BackofficeStateModel) {
        return state.navMenu;
    }
    @Selector()
    static getActions(state: BackofficeStateModel) {
        return state.actions;
    }
    @Selector()
    static getDetailsToAprroval(state: BackofficeStateModel) {
        return state.detailsToAprroval;
    }

    @Action(setRolAction)
    SetRole(ctx: StateContext<BackofficeStateModel>, action: setRolAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, roles: action.payload });
    }

    @Action(setModulesAction)
    SetModulesAction(ctx: StateContext<BackofficeStateModel>, action: setModulesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, modules: action.payload });
    }
    @Action(setMenuAction)
    SetMenuAction(ctx: StateContext<BackofficeStateModel>, action: setMenuAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, navMenu: action.payload });
    }

    @Action(setActions)
    setActions(ctx: StateContext<BackofficeStateModel>, action: setActions) {
        const state = ctx.getState();
        ctx.setState({ ...state, actions: action.payload });
    }   

    @Action(setDetailsToAprroval)
    setDetailsToAprroval(ctx: StateContext<BackofficeStateModel>, action: setDetailsToAprroval) {
        const state = ctx.getState();
        ctx.setState({ ...state, detailsToAprroval: action.payload });
    }   

    

}