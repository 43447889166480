export const environment = {
  sessionLiveTime: 3600000,
  sessionExtendLiveTime: 30000,
  frontUrl: 'https://gestioncomercial.bancopichincha.com.co',
  production: true,
  clientId: '395f0185-3871-4d6d-9221-cbb2aa7a83b5',
  tenentId: 'https://pichinchacol.ciamlogin.com/c3dafbc6-7360-4869-840e-107878c53f11',
  webApi: 'https://d38l7h4lnwozc.cloudfront.net/',
  enableSourceStorage: false,
  sourceStorageKey:'mwSTVuW7LR',
  name: 'prod',
  otpValidation: 600,
  otp_extend_validation: 600,
  api: {
    host: 'https://api-libranza.bancopichincha.com.co/mto',
    host_experian: 'https://apides.pibank.ws/api/experian-libranza/1.0.0/',
    host_data_credit: 'https://a3eypgnx27.execute-api.us-east-1.amazonaws.com/pibank/validarSeguridadSocial',
    host_mareigua: 'https://apides.pibank.ws/api/mareigua/1.0.0/analitics',
    host_grm_plus: 'https://e6ip6c2oj3.execute-api.us-east-1.amazonaws.com/qa/saveMotorGMR',
    paths: {
      agreement_list: '/convenios-listas',
      agreements: '/params-convenios',
      audit_logs: '/logs-auditoria',
      calculator: '/calculadora',
      capacity_discounts: '/matriz-capacidad-descuento',
      credit_lines: '/score-minimo',
      commercialPlant: '/planta-comercial',
      commission: '/comision-estucred',
      document_type: '/consulta-tipo-documento',
      fidelization: '/fidelizacion',
      inspektor: '/inspektor',
      max_rate: '/tasa-maxima-usura',
      max_simulation: '/max-simulacion',
      min_ammount: '/monto-min',
      otp_service: '/envio-mensaje-cliente',
      otp_signature: '/parametros-firma-otp',
      prospect: '/prospecto',
      rate_asing: '/asignacion-tasa',
      rate: '/plazo-min',
      reassignments: '/reasignaciones-venta-prospecto',
      report_autorization: '/autorizaciones-reporte',
      score_range: '/rangos-score',
      search_prospect: '/consulta-cliente-prosp',
      session_terms: '/sesion-terminos',
      simulation_persist: '/persistencia-simulador',
      special_rate: '/tasa-especial-camp',
      structure_rates: '/estructura-grupos-tasas',
      term_rate_range: '/rango-plazo-tasa',
      voluntary_insurance: '/seguros-voluntarios',
    },
    versions: {
      v1: '/v1',
    }
  }
};
export const environmentApi = {
  profile_api: 'https://foknlp4yre.execute-api.us-east-1.amazonaws.com/Dev/',
  infoLibranzaGet: environment.api.host + '/textos-parametricos/v1/getTextosParametricos/1',
  infoCaracSeguros: environment.api.host + '/textos-parametricos/v1/getTextosParametricos/2',
  infoSegurosVoluntarios: environment.api.host + '/textos-parametricos/v1/getTextosParametricos/3',
  infoTablaCaracSeguros: environment.api.host + '/seguros-de-vida/v1/findParametersInsuranceLife',
  infoTablaSegVoluntarios: environment.api.host + '/seguros-voluntarios/v1/findVoluntaryInsuranceFinal',
  gruposMilitares: environment.api.host + '/grupo-militar/v1',
  getTermsCredit:  environment.api.host + '/seguros-de-vida/v1/findRangeAge',
  GetContractType: environment.api.host + '/tipos-contratos/v1/obtenerTipoContratoActivos',
  getSimulationMax: environment.api.host + '/max-simulacion/v1/obtenerMaximoSimulacionPorParametros',
  getLegalDiscounts: environment.api.host + '/decuentos-ley/v1/obtenerDescuentosLey',
  getMinimumSalary: environment.api.host + '/parametro-salario-minimo/v1/obtenerSalarioMinimo',
  srvReassignment:environment.api.host + environment.api.paths.reassignments + environment.api.versions.v1,
  srvCommercialPlant: environment.api.host + environment.api.paths.commercialPlant + environment.api.versions.v1,
};
export const environmentLinkOtp ='https://validacliente.bancopichincha.com.co/dashboard';
export const apiAgreements = {
  consultAgreements: `${environment.api.host}/convenios-consulta/v1/graphql`, 
  findTasasGrupoTasaFinal:  `${environment.api.host}/estructura-grupos-tasas/v1/findTasasGrupoTasaFinal`,
  obtenerTasasVigentes: `${environment.api.host}/convenios-listas/v1/obtenerTasasVigentes`,
  obtenerUmbralCompraCartera: `${environment.api.host}/umbral-compra-cartera/v1/obtenerUmbralCompraCartera`,
}