import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { actions, PermDataModel, roles, Modules } from '../../model/PermDataModel';
import { environmentApi } from '../../../../environments/environment';
import { BackofficeState } from '../../model/state/backoffice.state';
import { setActions, setModulesAction } from '../../model/state/backoffice.actions';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  @Select(BackofficeState.getRoles) roles$: Observable<string[]> | any ;
  @Select(BackofficeState.getActions) actions$: Observable<actions[]> | any ;

  roles:string[] = [];
  actions:actions[] = [];
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    "Access-Control-Allow-Origin": "*"
  });
  constructor(private http: HttpClient,private store: Store) {
    this.roles$.subscribe((res: string[]) => {
      this.roles = res;
    });
    this.actions$.subscribe((res: actions[]) => {
      this.actions = res;
    });
   }


  getNavMenu(rol_named: string): Observable<HttpResponse<any>> {
    let request = {
      "rolNames":rol_named
    }
    return this.http.post<any>(`${environmentApi.profile_api}/getNavMenu`,
      request, { headers: this.headers, observe: 'response' })
  }

  getPermDataModule(): Observable<HttpResponse<PermDataModel>> {
    return this.http.get<PermDataModel>(`${environmentApi.profile_api}/getPermDataModule`,
      { headers: this.headers, observe: 'response' })
  }

  editSelectedRol(rol:roles): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${environmentApi.profile_api}/editSelectedRol`,
      rol, { headers: this.headers, observe: 'response' })
  }

  buildData(dataModel:PermDataModel | any) {
    let rolBuildedData: roles[] = [];
    try {
      dataModel?.roles?.forEach((rolin: { id: number; rolName: string; state: boolean; }) => {
        let rolBuilded: roles = new roles();
        rolBuilded.id = rolin.id;
        rolBuilded.rolName = rolin.rolName;
        rolBuilded.state = rolin.state;
        rolBuilded.modules = [];
        rolBuilded.actions = [];
        //se adicionan modulos
        dataModel.Modules.forEach((mod: { id: number; name: string; route: string; state: boolean; moduleName: string; subModule: string; }) => {
          let module: Modules = new Modules();
          module.id = mod.id;
          module.name = mod.name;
          module.route = mod.route;
          module.state = mod.state;
          module.moduleName = mod.moduleName;
          module.subModule = mod.subModule;
          module.active = false;
          let perm = dataModel.perm.find((x: { rol_id: number; menuOperation_id: number; }) => x.rol_id == rolin.id && x.menuOperation_id == mod.id)
          if (perm && perm.id != undefined) {
            module.active = true;
          }
          rolBuilded.modules.push(module);
        });
        //se adicionan acciones
        dataModel.actions.forEach((act: { id: number; menuOperation_id: number; state: boolean; accionName: string; }) => {
          let action: actions = new actions();
          action.id = act.id;
          action.rol_id = rolin.id;
          action.menuOperation_id = act.menuOperation_id;
          action.state = act.state;
          action.menuOperationName = dataModel.Modules.find((x: { id: number; }) => x.id == act.menuOperation_id).name;
          action.moduleName = dataModel.Modules.find((x: { id: number; }) => x.id == act.menuOperation_id).moduleName;
          action.subModule = dataModel.Modules.find((x: { id: number; }) => x.id == act.menuOperation_id).subModule;
          action.accionName = act.accionName;
          let perm = dataModel.perm.find((x: { rol_id: number; actionId: string | number; }) => x.rol_id == rolin.id && (+x.actionId) == act.id)
          if (perm && perm.id != undefined) {
            action.active = true;
          }
          rolBuilded.actions.push(action);
        });
        rolBuildedData.push(rolBuilded);

      });
    } catch (error: any) {
      Swal.fire({ icon: 'error', title: 'Error', text: error.message });
    }
    let modules = rolBuildedData.filter(x => this.roles?.includes(x.rolName)).map(x => x.modules.filter(y => y.active == true)).flat();
    let activeActions = rolBuildedData.filter(x => this.roles?.includes(x.rolName)).map(x => x.actions.filter(y => y.active == true)).flat();
    this.store.dispatch(new setModulesAction(modules));
    this.store.dispatch(new setActions(activeActions));
    return rolBuildedData
  }

  isEnableAction(idAction: number)
  {
    console.log('id',idAction)
    console.log('actions',this.actions)
   let action = this.actions.filter(x => x.id == idAction);
   console.log('action',action)
   return action.length > 0;
  }
}
