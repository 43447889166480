
export class setRolAction {
    static readonly type = '[Roles] setRolAction';
    constructor(public payload: any[]) { }
}
export class setModulesAction {
    static readonly type = '[Modules] setModulesAction';
    constructor(public payload: any[]) { }
}
export class setMenuAction {
    static readonly type = '[NavMenu] setMenuAction';
    constructor(public payload: any[]) { }
}
export class setActions {
    static readonly type = '[actions] setActions';
    constructor(public payload: any[]) { }
}
export class setDetailsToAprroval {
    static readonly type = '[any] setDetailsToAprroval';
    constructor(public payload: any[]) { }
}
