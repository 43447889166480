import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { BehaviorSubject, filter, firstValueFrom, mergeMap, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AccountInfo, EventMessage, EventType } from '@azure/msal-browser';
import { environment as ENV } from 'environments/environment';
import { ProfileService } from '@global-shared/services/profile-service/profile-service.service';
import { Store } from '@ngxs/store';
import { setRolAction } from '@global-shared/model/state/backoffice.actions';
@Injectable()

export class AuthService {
  private _activeAccounts: AccountInfo[] = [];
  private readonly _$currentAccount: BehaviorSubject<AccountInfo> = new BehaviorSubject({} as any);
  private _currentAccount: AccountInfo = {} as any;
  private isExitAccount: boolean = false;
  constructor(
    private readonly msalService: MsalService,
    private readonly http: HttpClient,
    private readonly _mB: MsalBroadcastService,
    private porfileService: ProfileService,
    private store: Store,
  ) {}

  getUser(): string {
    return this.msalService?.instance?.getActiveAccount()?.name || '';
  }

  checkAndSetActiveAccount() {
    const storedRoles = localStorage.getItem('roles');
    this._activeAccounts = this.msalService.instance.getAllAccounts();
    if (this._activeAccounts.length === 1) {
      this.msalService.instance.setActiveAccount(this._activeAccounts[0]);
    }
    this.msalService.instance.setActiveAccount(this._activeAccounts[0]);
    this._$currentAccount.next({ ...this._activeAccounts[0] });
    this._currentAccount = this._activeAccounts[0];
    if (this._activeAccounts.length > 0) {
      if (storedRoles == null || storedRoles == "") {
        const roles: any = this.msalService.instance.getActiveAccount()?.idTokenClaims?.roles ?? [];
        this.store.dispatch(new setRolAction(roles));
        //bloque para obtener el menu dinamico
        let split: string[] = roles?.toString().split(',');
        let rol: string = '';
        for (let index = 0; index < split?.length; index++) {
          rol = rol + "'" + split[index] + "'";
          if (index < split.length - 1) {
            rol = rol + ',';
          }
        }
        roles && localStorage.setItem('roles', roles.toString());
        this.porfileService.getPermDataModule().subscribe((res) => {
          this.porfileService.buildData(res.body);
        });
      }
      this._getTokenData();
    } else {
      this._mB.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        )
        .subscribe((result: EventMessage) => {
          console.log('Login success result:', result);
          this.checkAndSetActiveAccount();
        });
    }
  }

//checkAndSetActiveAccount () {
//  const accounts = this.msalService.instance.getAllAccounts();
//  if (accounts.length === 1) {
//    this.msalService.instance.setActiveAccount(accounts[0]);
//  }
//
//  const roles: any =this.msalService.instance.getActiveAccount()?.idTokenClaims?.['roles'];
//
//
//
//  this.store.dispatch(new setRolAction(roles));
//  //bloque para obtener el menu dinamico
//  let split: string[] = roles?.toString().split(',');
//  let rol: string = '';
//  for (let index = 0; index < split?.length; index++) {
//    rol = rol + "'" + split[index] + "'";
//    if (index < split.length - 1) {
//      rol = rol + ',';
//    }
//  }
//  this.porfileService.getPermDataModule().subscribe((res) => {
//    this.porfileService.buildData(res.body);
//  });
//
//  //fin menu
//  roles && localStorage.setItem('roles', roles.toString());
//  this._getTokenData();
//  this.msalService
//    .acquireTokenSilent({
//      scopes: ['user.read', 'openid', 'profile'],
//    })
//
//}

  getIp() {
    return this.http.get<{ ip: string }>('https://api.ipify.org?format=json');
  }

  logout(): void {
    this.isExitAccount = true;
    if(this._activeAccounts.length > 1){
      this.msalService.logoutPopup({ mainWindowRedirectUri: '/' })
    } else {
      const account = this.msalService.instance.getAccountByHomeId(this._currentAccount.homeAccountId);
      const logoutHint = account?.idTokenClaims?.login_hint ?? this._currentAccount.username;
      this.msalService.logout({
        logoutHint,
        authority: ENV.tenentId
      } as any);
      this._clearInstance();
    }
  }

  private _clearInstance(){
    this.msalService.instance.setActiveAccount(null);
    this.msalService.instance.clearCache();
    localStorage.clear();
    sessionStorage.clear();
  }

  getUserData(): { user_name: string, user_mail: string } {
    const user: AccountInfo = this.msalService.instance.getActiveAccount()!
    return { user_name: user?.name ?? '', user_mail: user?.username } ;
  }


  private async _getTokenData(): Promise<boolean> {
    try {
      const res = await firstValueFrom( this.msalService.acquireTokenSilent(
        { account: this._currentAccount, scopes: ['user.read', 'openid'], authority: ENV.tenentId }
      ))
      res.idToken && localStorage.setItem('acc_tkn', res.idToken );
      return true;
    } catch (error) {
      return false
    }
  }

  get currentUser(): Observable<AccountInfo>  {
    return this._$currentAccount;
  }

  get finalCurrentUser(): AccountInfo {
    return this._currentAccount;
  }

  get exitAccount(){
    return this.isExitAccount;
  }

}
