export class PermDataModel {
    validFile!: boolean;
    roles!: roles[];
    perm!: perm[];
    Modules!: any[];
    actions!: actions[];
}

export class roles {
    id!: number;
    rolName!: string;
    state!: boolean;
    modules!: Modules[];
    actions!: actions[];
}
export class perm {
    id!: number;
    rol_id!: number;
    menuOperation_id!: number;
    state!: boolean;
    actionId!: string;
}
export class actions {
    id!: number;
    rol_id!: number;
    menuOperation_id!: number;
    state!: boolean;
    menuOperationName!: string;
    active!: boolean;
    moduleName!: string;
    subModule!: string;
    accionName!: string;
}
export class Modules {
    id!: number;
    name!: string;
    route!: string;
    state!: boolean;
    moduleName!: string;
    subModule!: string;
    active!: boolean;
}
